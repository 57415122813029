import "../styles/index.scss";
import AOS from "aos";
import "aos/dist/aos.css";

// Appear on scroll
AOS.init({ easing: "ease-out", duration: 400 });

// FAQ
const questions = document.querySelectorAll(".question");
questions.forEach((question) => {
  question.addEventListener("click", () => {
    question.classList.toggle("active");
  });
});

// Popup
const openPopupButton = document.getElementById("download-kit");
const popup = document.getElementById("popup");
const closeButton = document.querySelector(".close");

openPopupButton.addEventListener("click", function () {
  popup.classList.toggle("show");
});

closeButton.addEventListener("click", function () {
  popup.classList.toggle("show");
});

window.addEventListener("click", function (event) {
  if (event.target == popup) {
    popup.classList.toggle("show");
  }
});

// Form
const form = document.querySelector(".form");
const successMessage = document.getElementById("success-message");
const errorMessage = document.getElementById("error-message");
const downloadButton = document.getElementById("download-button");

function downloadZip() {
  const link = document.createElement("a");
  link.href = "/uploads/kit-inao-2024.zip";
  link.download = "kit-inao-2024.zip";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

downloadButton.addEventListener("click", downloadZip);

form.addEventListener("submit", function (event) {
  event.preventDefault();

  const formData = new FormData(event.target);
  fetch("https://forms.dahive.fr/inao2024/submit-form", {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "success") {
        successMessage.style.display = "block";
        errorMessage.style.display = "none";
        form.style.display = "none";
        downloadButton.style.display = "block";
      } else {
        successMessage.style.display = "none";
        errorMessage.style.display = "block";
      }
    })
    .catch(() => {
      successMessage.style.display = "none";
      errorMessage.style.display = "block";
    });
});
